var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.currentAppointment)?_c('div',[_c('div',{staticClass:"display-flex align-center gap-xs-ppNew"},[(_vm.allowRedirectToAppointment)?_c('div',{class:{
        icon: true,
        'alert-icon': _vm.currentAppointment.hasIssues
      }}):_vm._e(),_c('div',{class:{
        'text-l-semibold': true,
        apptsHeading: true,
        'is-success':
          _vm.allowRedirectToAppointment && !_vm.currentAppointment.hasIssues,
        'is-error': _vm.allowRedirectToAppointment && _vm.currentAppointment.hasIssues
      }},[_vm._v(" Upcoming appointment ")])]),_c('div',{staticClass:"margin-top-xs-ppNew text-m-semibold"},[_vm._v(" "+_vm._s(_vm.currentAppointment.location.name)+" ")]),_c('div',{staticClass:"margin-top-2xs-ppNew display-flex flex-column gap-2xs-ppNew text-m"},[_c('div',{staticClass:"display-flex justify-space-between"},[_c('div',[_vm._v("Provider")]),_c('div',[_vm._v(" "+_vm._s(_vm.currentAppointment.provider.first_name)+" "+_vm._s(_vm.currentAppointment.provider.last_name)+" ")])]),_c('div',{staticClass:"display-flex justify-space-between"},[_c('div',[_vm._v("Service date")]),_c('div',[(_vm.allowRedirectToAppointment)?_c('a',{staticClass:"text-m-semibold blue-100-ppNew",attrs:{"href":_vm.currentAppointment.url,"target":"_blank"}},[_c('span',[_vm._v(" "+_vm._s(_vm.currentAppointment.formatted_service_date)+" ")])]):_vm._e(),(!_vm.allowRedirectToAppointment)?_c('span',[_vm._v(" "+_vm._s(_vm.currentAppointment.formatted_service_date)+" ")]):_vm._e()])]),(_vm.currentAppointment.check_in)?_c('div',{staticClass:"display-flex justify-space-between"},[_c('div',[_vm._v("Digital check-in status")]),_c('div',{class:{
          'text-xs': true,
          status: true,
          [_vm.currentAppointment.check_in_display_status]: true
        }},[_vm._v(" "+_vm._s(_vm.currentAppointment.check_in_display_status.replaceAll("_", " "))+" ")])]):_vm._e(),(_vm.kioskCode)?_c('div',[_c('div',{staticClass:"display-flex justify-space-between"},[_c('div',[_vm._v("Kiosk code")]),_c('div',{staticClass:"text-m-semibold"},[_vm._v(_vm._s(_vm.kioskCode))])])]):_vm._e()]),(_vm.allowRedirectToAppointment && _vm.currentAppointment.url)?_c('div',{staticClass:"margin-top-m-ppNew"},[_c('AppointmentIssues',{attrs:{"display_issue_blocks":_vm.currentAppointment.check_in.display_issue_blocks,"apptUrl":_vm.currentAppointment.url}})],1):_vm._e(),_c('div',{staticClass:"margin-top-m-ppNew"},[_c('PatientCheckInActions',{attrs:{"appointment":_vm.currentAppointment}})],1),(this.appointments.length > 1)?_c('div',{staticClass:"margin-top-xs-ppNew display-flex justify-space-between"},[(this.currentAppointmentIndex !== 0)?_c('button',{staticClass:"text-m-semibold blue-100-ppNew controlBtn",attrs:{"type":"button"},on:{"click":_vm.moveBack}},[_c('div',{staticClass:"icon caret-icon"}),_vm._v(" Previous ")]):_vm._e(),(this.currentAppointmentIndex !== this.appointments.length - 1)?_c('button',{staticClass:"text-m-semibold blue-100-ppNew controlBtn right",attrs:{"type":"button"},on:{"click":_vm.moveForward}},[_vm._v(" Next "),_c('div',{staticClass:"icon caret-icon right"})]):_vm._e()]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }