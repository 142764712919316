<template>
  <div id="app">
    <ProductClaimConfirmationDlg v-if="showPayClaim" />

    <div v-if="!showPayClaim" class="app-content">
      <AddCard v-if="showAddCard" v-bind:dialog-mode="chargeMode"> </AddCard>
      <PaymentPlan v-if="showEditPlan && !showAddCard"> </PaymentPlan>

      <RequestConsent
        v-if="requestConsentDialog.visible"
        v-bind:on-finish="requestConsentDialog.onFinish"
      ></RequestConsent>
      <LoginForm
        v-if="!currentUser && !isOTPRequired"
        :shouldFetchPatientOnLogin="true"
      ></LoginForm>
      <LoginOTPForm
        v-if="!currentUser && isOTPRequired"
        :shouldFetchPatientOnLogin="true"
      ></LoginOTPForm>
      <div v-if="error" class="has-text-danger">
        {{ error }}
        <b-button
          v-if="accessDenied"
          class="button"
          style="margin-top: 1em"
          @click="logout()"
        >
          Re-login
        </b-button>
      </div>

      <div
        v-if="currentUser && !patientId && !patients && !showPayClaim"
        class="flex-a"
      >
        Sidebar shows information about currently selected patient. Please
        navigate to the patient details to use the sidebar.
      </div>

      <div
        v-if="currentUser && patients && patients.length > 1 && !patient"
        class="flex-a"
      >
        <p>
          There are multiple patients with the same AccountID {{ patientId }} in
          Collectly. Please select the patient you want to view in the sidebar:
        </p>
        <div v-for="p in patients" :key="p.id" class="patient-selector">
          <a @click="selectPatient(p)"
            >#{{ p.id }} {{ p.first_name }} {{ p.last_name }}</a
          ><br />
          <span>{{ p.client_name }}, ${{ p.current_balance }}</span>
        </div>
      </div>

      <b-tabs v-if="currentUser && patient" v-model="activeTab" expanded>
        <b-tab-item label="Activity">
          <PatientDetails :isVisible="activeTab === 0"></PatientDetails>
        </b-tab-item>

        <b-tab-item label="Messages" v-if="currentUserRoleUpperSupport">
          <PatientMessages></PatientMessages>
        </b-tab-item>
      </b-tabs>

      <div v-if="isLoading" class="loader-overlay">
        <font-awesome-icon
          icon="circle-notch"
          spin
          size="3x"
        ></font-awesome-icon>
      </div>
      <div v-if="!showPayClaim" class="sidebar-footer flex-0">
        <div>To ensure the app functions correctly, you need to enable cookies.</div>
        This HIPAA-compliant add-on made by Collectly, Inc. <br />
        All rights reserved © 2024
      </div>
    </div>
  </div>
</template>

<style lang="scss">
// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy-build";
@import "./scss/common.scss";

html,
body {
  height: 100%;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black-100;
  overflow: hidden;
  background: none !important;
}

#app {
  height: 100%;
  margin-left: auto;
  overflow: hidden;
}

.app-content {
  padding: 0.5rem 1rem;
  max-width: 320px;
  height: 100%;
  border: none;
  background: #ffffff;
  overflow: auto;
  margin-left: auto;
}

.b-tabs {
  .tab-content {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  li {
    @extend .text-m-semibold;

    a {
      color: $grey-70;
      padding-top: $gap-s-ppNew;
      padding-bottom: $gap-s-ppNew;
      border-bottom-width: 2px;
    }

    &.is-active {
      a {
        color: $black-100;
      }
    }
  }
}

.b-radio {
  &.radio {
    margin-top: 0.5rem;

    input[type="radio"] + .check {
      width: 1.286em;
      height: 1.286em;
    }

    input[type="radio"] + .check:before {
      margin-left: calc(-1.286em / 2);
      margin-bottom: calc(-1.286em / 2);
      width: 1.286em;
      height: 1.286em;
    }
  }
}

.loader-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-footer {
  margin-top: 0.5em;
  font-size: 0.75em;
  color: $gray-1;
}

.patient-selector {
  margin-top: 0.5em;

  span {
    display: block;
    color: $gray-1;
  }
}

.flex-a {
  flex: 1 0 auto;
}

.flex-0 {
  flex: 0 1;
}

// Workaround for Buefy bug for fields with addons
.field {
  &.has-addons {
    flex-wrap: wrap;

    > .control {
      flex: 1;
    }

    > .help {
      width: 100%;
    }

    > .label {
      width: 100%;
    }
  }
}

.input[readonly] {
  background: $gray-4 !important;
}

// Container that the modal scrolls within
.modal-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;

  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;

  overflow-x: hidden;

  display: flex !important;
  height: 100%;
  align-items: center;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background-color: #000000;
  opacity: 0.2;
}

// Shell div to position the modal with bottom padding (modal-dialog)
.modal-fullscreen {
  position: relative;
  width: auto;
  margin: 0 auto;
  z-index: 10001;
  opacity: 1;

  /*  @media (min-width: 1024px) {
    max-width: 75%;
  }

  @media (min-width: 1280px) {
    max-width: 50%;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }*/

  // Actual modal
  .modal-content {
    position: relative;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    background-clip: padding-box;
    // Remove focus outline from opened modal
    outline: 0;
    padding: 1em 2em;
    overflow: auto;
  }

  // Modal body
  .modal-body {
    position: unset;
    margin: 0 1em;

    span.capitalize {
      text-transform: capitalize !important;
    }

    &.modal-body-left {
      text-align: left;
    }
  }

  // Modal header
  // Top section of the modal w/ title and dismiss
  .modal-header {
    padding: 1em;
    // border-bottom: 1px solid $gray-4;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .modal-footer {
    margin-top: 1.5rem;
    padding: 1em;

    .buttons {
      justify-content: center !important;
    }
  }

  // Close icon
  .modal-header .close {
    margin-top: -2px;
  }

  .modal-title {
    font-size: 1.5em;
    font-weight: bold;
  }

  .flex-spacing {
    display: flex;

    > *:not(:last-child) {
      margin-right: 1em;
    }
  }

  .card-template {
    display: flex;
    flex-wrap: wrap;

    > *:not(:last-child) {
      margin-right: 1rem;
    }
  }

  .autocomplete {
    border: none !important;
  }
}

.card-icon {
  display: inline-block;
  background-repeat: no-repeat;
  width: 24px;
  height: 16px;
  vertical-align: middle;
}

.card-mastercard {
  background-image: url(./assets/card-mastercard.svg);
}

.card-visa {
  background-image: url(./assets/card-visa.svg);
}

.card-americanexpress {
  background-image: url(./assets/card-amex.svg);
}

.card-amex {
  background-image: url(./assets/card-amex.svg);
}

.card-discover {
  background-image: url(./assets/card-discover.svg);
}

.card-dinersclub {
  background-image: url(./assets/card-diners.svg);
}

.card-diners {
  background-image: url(./assets/card-diners.svg);
}

.card-jcb {
  background-image: url(./assets/card-jcb.svg);
}

.card-unionpay {
  background-image: url(./assets/card-unionpay.svg);
}
</style>

<script>
import AddCard from "./components/AddCard";
import LoginForm from "./components/Login";
import LoginOTPForm from "./components/LoginOTP";
import PatientDetails from "./components/PatientDetails";
import PatientMessages from "./components/PatientMessages";
import PaymentPlan from "./components/PaymentPlan";
import RequestConsent from "./components/RequestConsent";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCircleNotch,
  faInfoCircle,
  faSync,
  faTimes,
  faCheckCircle,
  faBan,
  faExclamationCircle,
  faList,
  faQuestionCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import moment from "moment";
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import ProductClaimConfirmationDlg from "@/components/ProductClaimConfirmationDlg.vue";

library.add(
  faSync,
  faCircleNotch,
  faTimes,
  faInfoCircle,
  faCheckCircle,
  faBan,
  faExclamationCircle,
  faList,
  faQuestionCircle
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.filter("toUSD", function(value) {
  if (typeof value !== "number") {
    return "$0.00";
  }
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});

Vue.filter("formatDate", function(value, formatStr, parseTimezone) {
  if (value) {
    if (Number.isInteger(value)) {
      if (value % 1000 === 0) value = value / 1000;
      return moment.unix(value).format(formatStr || "YYYY-MM-DDTh:mm:ss");
    } else {
      if (parseTimezone) {
        return moment
          .parseZone(value)
          .format(formatStr || "YYYY-MM-DDTh:mm:ss");
      } else {
        return moment(value).format(formatStr || "YYYY-MM-DDTh:mm:ss");
      }
    }
  }
  return "-";
});

export default {
  name: "App",
  components: {
    ProductClaimConfirmationDlg,
    PatientMessages,
    LoginForm,
    LoginOTPForm,
    PatientDetails,
    PaymentPlan,
    RequestConsent,
    AddCard,
    FontAwesomeIcon
  },
  data() {
    return {
      activeTab: 0,
      showBooks: false
    };
  },
  created() {
    window.parent.postMessage({ eventName: "showToggleFloatingButton" }, "*");
  },
  methods: {
    ...mapActions([
      "login",
      "logout",
      "resync",
      "showEditPlanDlg",
      "selectPatient"
    ])
  },
  computed: mapState({
    patients: state => state.patients,
    currentUser: state => state.user,
    currentUserRoleUpperSupport: state => state.user?.role >= 5 ?? false,
    patientId: state => state.patientId,
    patient: state => state.patient,
    error: state => state.error,
    isLoading: state =>
      state.status === "loading" || state.status === "otp-required-loading",
    isOTPRequired: state =>
      state.status === "otp-required" ||
      state.status === "otp-required-error" ||
      state.status === "otp-required-loading",
    showEditPlan: state => state.dialogOpen.editPlan,
    showPayClaim: state => state.dialogOpen.payClaim,
    showAddCard: state => state.dialogOpen.addCard,
    chargeMode: state => state.chargeMode,
    requestConsentDialog: state => ({
      visible: state.dialogOpen.requestConsent,
      onFinish: state.dialogCallback.requestConsent
    }),
    accessDenied: state => state.accessDenied
  })
};
</script>
